import React from 'react';
import Modal from '../../../components/Modal';
import Quote from '../../../components/Quote';

const media = {
  secTitle: 'People Connect Around Cultural & Sporting Events',
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/CaMvI0FPRzM',
  },
};

export default () => (
  <Modal closeTo="Learned" media={media}>
    <p className="Title--2 Title--c">
      Opportunities for people to connect and find a common purpose across
      racial lines are often centered on cultural and sporting events.
    </p>
    <p>
      Across regions and demographic groups, most residents agreed that they are
      frequently brought together by sports, arts, common cultural interests,
      and events. Sports came up most often as a unifying force. According to a
      2011 ESPN survey, 72 percent of respondents believed that sports do more
      to unite people across racial lines, whereas only 6 percent believed that
      sports do more to divide people across racial lines.
    </p>
    <p>
      Many local arts and cultural organizations pointed to a lack of resources
      or tools necessary to ensure these spaces are utilized to their fullest
      potential. Most wish there were more opportunities, outside of school and
      the workplace, that focus on bringing diverse groups of people together
      and providing the space for people to connect across cultures and
      identities. Some people also pointed out the barriers to participating in
      such enriching opportunities, including expense and transportation.
    </p>
    <p>
      Faith was the next most noted theme, but both as something that unites and
      divides communities along racial lines. Tragedy was also noted as
      something that consistently brings people together.
    </p>

    <Quote
      quote="But to me recreation, schools, cultural events are very important and I guess the sense of community. You’ve got a great divide in the community and it’s hard to bring them all together."
      person="White male, 54"
      title="Knoxville"
    />
    <Quote
      quote="If you are talking about diversity coming together, there’s still not a lot of that happening unless you’re talking about game day football basically. High school football is still a great time."
      person="White male"
      title="Jackson"
    />
    <Quote
      quote="Here, everything costs literally hundreds of dollars whether it be cheerleading or basketball or track or football or basketball. And with three kids in school trying to do three different sports and each one is a $300 to $500 investment, it makes it hard, it makes it hard."
      person="Black male, 40"
      title="Knoxville"
    />
    <Quote
      quote="Most of the parks have Friday night flicks, and they’ll have movies in the park...And so it provides a place for people to meet other cultures."
      person="White female"
      title="Bentonville"
    />
  </Modal>
);
